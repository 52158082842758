body {
	font-family: $font-family-main;
	font-weight: 400;
	color: $color-brand-dark-gray;
}

img {
	max-width: 100%;
	height: auto;
}

:host ::ng-deep {
	body {
		font-family: $font-family-main;
		font-weight: 400;
		color: $color-brand-dark-gray;
	}

	img {
		max-width: 100%;
		height: auto;
	}
}
