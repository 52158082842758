/* You can add global styles to this file, and also import other style files */
// @import "~@fortawesome/fontawesome-free/css/all.min.css";
@import '~bootstrap/scss/bootstrap';
@import 'app/styles/partials/variables';
@import 'app/styles/partials/global';
// @import '~bootstrap/dist/css/bootstrap.css';

@import '~@fortawesome/fontawesome-free/css/all.min.css';

.cursor-pointer {
	cursor: pointer;
}
