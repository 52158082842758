$color-white: #ffffff;
$color-lt-gray: #ddd;
$color-lt-gray-alt: #dcdee5;
$color-xtra-lt-gray: #f0f1f4;
$color-medium-gray: #848ca3;
$color-brand-dark-gray: #6a6d7f;
$color-brand-purple: #4e40f3;
$color-brand-purple-light: #5c4eff;

$color-dark-gray: #283045;

$font-family-heading: 'titillium-web', sans-serif;
$font-family-main: 'proxima-nova', sans-serif;
